import './Logo.scss';

const Logo = (props) => {
    return(

        <a href="home" className={props.state}>
            S
        </a>
    
    )
}

export default Logo;